import { render, staticRenderFns } from "./LayoutSrc.vue?vue&type=template&id=349c1b40"
import script from "./LayoutSrc.vue?vue&type=script&lang=js"
export * from "./LayoutSrc.vue?vue&type=script&lang=js"
import style0 from "./LayoutSrc.vue?vue&type=style&index=0&id=349c1b40&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports