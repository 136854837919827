<template>
  <div>
    <v-row no-glutters>
      <v-col cols="12">
        <v-card class="elevation-0 mb-4 mb-md-14 pa-4 pa-md-6 rounded-lg" color="#834EDA">
          <v-row no-glutters>
            <v-col cols="12" md="4">
              <p class="fs-26 d-md-none font-weight-bold lh-24 white--text">Invest in IPOs</p>
              <p class="fs-36 d-none d-md-flex font-weight-bold lh-32 white--text">Invest in IPOs</p>

              <p class="title white--text mb-0 lh-24 font-weight-regular">Initial public offering of a new stock issuance for the first time to market.</p>
              <!-- <v-btn
                                class="elevation-0 white--text font-weight-bold px-6 rounded-pill text-none fs-14 mb-2"
                                large color="#000000">Apply for an IPO</v-btn> -->
            </v-col>
            <v-col cols="7" class="d-none ml-auto mr-4 d-md-flex pos-rlt">
              <div style="width: 100%" no-glutters class="pos-abs d-inline-flex flex-row-reverse">
                <v-card width="170px" class="pb-4 mr-3 stk-land-crds rounded-xl text-center">
                  <img :src="require(`@/assets/ipos/main-card-3${$vuetify.theme.dark ? 'd' : ''}.svg`)" class="px-4 pt-6 pb-2" alt="main-card-3" width="79%" />
                  <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                    Check IPO <br />
                    gains post<br />
                    listing.
                  </p>
                </v-card>
                <v-card width="170px" class="pb-4 mr-3 stk-land-crds rounded-xl text-center">
                  <img :src="require(`@/assets/ipos/main-card-2${$vuetify.theme.dark ? 'd' : ''}.svg`)" class="px-4 pt-6 pb-2" alt="main-card-2" width="79%" />
                  <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                    Invest safely<br />
                    in small<br />
                    via UPI.
                  </p>
                </v-card>
                <v-card width="170px" class="pb-4 mr-3 stk-land-crds rounded-xl text-center">
                  <img :src="require(`@/assets/ipos/main-card-1${$vuetify.theme.dark ? 'd' : ''}.svg`)" class="px-4 pt-6 pb-2" alt="main-card-1" width="79%" />
                  <p class="fs-16 font-weight-bold lh-16 px-4 mt-1 mb-0">
                    Build wealth<br />
                    investing on<br />
                    main IPOs
                  </p>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <div class="default-tab">
          <v-tabs class="d-md-none" color="primary" fixed-tabs v-model="ipotype">
            <v-tab class="font-weight-bold subtitle-2 mb-0 text-none">
              <v-badge :content="iposdatas[0] ? iposdatas[0].length : ''" :value="iposdatas[0] ? iposdatas[0].length : ''">
                <span>Main stream IPOs</span>
              </v-badge>
            </v-tab>
            <v-tab class="font-weight-bold subtitle-2 mb-0 text-none">
              <v-badge :content="iposdatas[1] ? iposdatas[1].length : ''" :value="iposdatas[1] ? iposdatas[1].length : ''">
                <span>SME IPOs</span>
              </v-badge>
            </v-tab>
            <v-tabs-slider></v-tabs-slider>
          </v-tabs>
          <v-tabs class="d-none d-md-flex" color="primary" fixed v-model="ipotype">
            <v-tab class="font-weight-bold subtitle-2 mb-0 text-none">
              <v-badge :content="iposdatas[0] ? iposdatas[0].length : ''" :value="iposdatas[0] ? iposdatas[0].length : ''">
                <span>Main stream IPOs</span>
              </v-badge>
            </v-tab>
            <v-tab class="font-weight-bold subtitle-2 mb-0 text-none">
              <v-badge :content="iposdatas[1] ? iposdatas[1].length : ''" :value="iposdatas[1] ? iposdatas[1].length : ''">
                <span>SME IPOs</span>
              </v-badge>
            </v-tab>
            <v-tabs-slider></v-tabs-slider>
            <!-- <v-spacer></v-spacer>
                    <v-tab class="font-weight-bold subtitle-2 mb-0 text-none float-right">
                        IPO Performance {{ new Date().getFullYear() }}
                    </v-tab> -->
          </v-tabs>
        </div>

        <v-card style="border: 1px solid var(--outline)" class="crd-trn elevation-0 rounded-lg mb-0 d-none d-md-block">
          <v-toolbar class="tool-sty elevation-0 py-4 mb-7 px-4 crd-trn" dense>
            <v-list-item class="pl-0">
              <v-list-item-icon class="mr-4">
                <img width="80%" :src="require(`@/assets/ipos/main-card-${ipotype == 0 ? '1' : '2'}${$vuetify.theme.dark ? 'd' : ''}.svg`)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold title">{{ ipotype == 0 ? "Main stream IPOs" : "Small and Medium Enterprises IPOs" }}</v-list-item-title>
                <v-list-item-subtitle class="fs-12 subtext--text">{{ ipotype == 0 ? "Initial public offering a new stock issuance for the first time." : "Small and Medium Enterprises a new stock issuance for the first time." }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-data-table
            must-sort
            :sort-by="['biddingStartDate']"
            :sort-desc="[false]"
            hide-default-footer
            fixed-header
            :loading="loading"
            class="rounded-0 overflow-y-auto"
            :headers="tradeheader"
            height="calc(100vh - 180px)"
            :items="iposdatas[ipotype]"
            :items-per-page="iposdatas[ipotype] && iposdatas[ipotype].length > 0 ? showtable : 0"
            item-key="Scheme_Code"
          >
            <template v-slot:body="{items, headers}">
              <tbody v-if="iposdatas[ipotype] && iposdatas[ipotype].length > 0">
                <!-- @click="setMFrowdata(item)" -->
                <tr class="table-row" v-for="(item, o) in items" :key="o">
                  <td class="pos-rlt" cols="1">
                    <P class="fs-13 font-weight-medium mb-0 maintext--text table-hov-text mt-2">
                      {{ item.name ? item.name : "" }}
                    </P>
                    <v-chip-group column class="mb-1">
                      <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                        <span class="font-weight-medium fs-10">
                          {{ item.symbol ? item.symbol : "" }}
                        </span>
                      </v-chip>
                      <!-- C1E7BA FEDE9F-->
                      <v-chip :color="item.dateof ? '#FFF6E6' : 'secgreen'" x-small :text-color="item.dateof ? '#B37702' : 'maingreen'" style="border-radius: 5px; padding: 10px 8px !important">
                        <span class="font-weight-medium fs-10">
                          {{ item.dateof ? "Upcoming" : "Open" }}
                        </span>
                      </v-chip>
                    </v-chip-group>

                    <div @click.stop class="pos-abs table-hov" style="top: 8px; right: 0">
                      <v-btn @click="uid ? IposOrder(item, ipotype) : $emit('gologin')" small class="elevation-0 rounded-pill text-none primary--text font-weight-bold mr-3 mt-3" color="#F1F3F8">{{ item.dateof ? "Pre" : "" }} Apply</v-btn>
                    </div>
                  </td>
                  <td>
                    <span class="font-weight-medium">{{ item.datebit ? item.datebit : "" }}</span>

                    <!-- <v-list-item style="width: 220px;" class="px-0">
                                            <v-list-item-content>
                                                <v-list-item-subtitle class="font-weight-medium subtext--text fs-10">Start
                                                    date
                                                </v-list-item-subtitle>
                                                <v-list-item-title class="font-weight-bold caption">{{
                    item.biddingStartDate
                }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="font-weight-medium fs-10">{{
                        item.dailyStartTime }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content class="text-right">
                                                <v-list-item-subtitle class="font-weight-medium subtext--text fs-10">End date
                                                </v-list-item-subtitle>
                                                <v-list-item-title class="font-weight-bold caption">{{ `${new Date(item.biddingEndDate).toISOString().slice(8, 10)}-${new Date(item.biddingEndDate).toISOString().slice(5, 7)}-${new Date(item.biddingEndDate).toISOString().slice(0, 4)}` }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="font-weight-medium fs-10">{{
                    item.dailyEndTime
                }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item> -->
                  </td>
                  <td class="text-right font-weight-medium">₹{{ item.minPrice ? item.minPrice : "0.00" }} - ₹{{ item.maxPrice ? item.maxPrice : "0.00" }}</td>
                  <!-- <td class="text-right font-weight-medium">₹{{ item.cutOffPrice ? item.cutOffPrice : '0.00'
                                        }}</td> -->
                  <td class="text-right font-weight-medium">
                    ₹{{ item.minPrice ? item.minPrice * item.minBidQuantity : "0.00" }} <br /><span class="subtext--text font-weight-regular">{{ item.lotSize ? item.lotSize : "0" }} Qty.</span>
                  </td>
                </tr>
                <tr v-if="showtable < iposdatas[ipotype].length">
                  <td :colspan="headers.length">
                    <v-btn color="primary" plain class="elevation-0 text-none font-weight-bold py-4" @click="showtable += 24" block>show more</v-btn>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td :colspan="headers.length" class="text-center">
                  <div class="mx-auto py-16 mt-16">
                    <img class="mx-auto" width="80px" :src="require('@/assets/no data folder.svg')" />
                    <h4 class="txt-999 font-weight-regular caption">There is no {{ ipotype == 0 ? "IPOs" : ipotype == 1 ? "SME" : "" }} data here yet!</h4>
                  </div>
                </td>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
        <div class="d-md-none overflow-hidden">
          <v-divider></v-divider>
          <v-list-item class="pl-0">
            <v-list-item-icon class="mr-3">
              <img width="48px" :src="require(`@/assets/ipos/main-card-${ipotype == 0 ? '1' : '2'}${$vuetify.theme.dark ? 'd' : ''}.svg`)" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold fs-18">{{ ipotype == 0 ? "Main stream IPOs" : "Small and Medium Enterprises IPOs" }}</v-list-item-title>
              <p class="fs-12 subtext--text mb-0">{{ ipotype == 0 ? "Initial public offering a new stock issuance for the first time." : "Small and Medium Enterprises a new stock issuance for the first time." }}</p>
            </v-list-item-content>
          </v-list-item>
          <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
          <div v-if="iposdatas[ipotype] && iposdatas[ipotype].length > 0">
            <v-card v-for="(item, o) in iposdatas[ipotype].length < showtable ? iposdatas[ipotype].slice(0, showtable) : iposdatas[ipotype]" :key="o" class="elevation-0 rounded-0 overflow-hidden crd-trn">
              <div class="px-0">
                <v-list-item class="pa-0">
                  <v-list-item-content class="pa-0 pt-1">
                    <v-list-item-title class="fs-13 font-weight-bold mb-0 maintext--text table-hov-text mt-2">
                      {{ item.name ? item.name : "" }}
                    </v-list-item-title>
                    <v-chip-group column class="mb-1">
                      <v-chip color="secbg" x-small class="table-hov-prd" text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                        <span class="font-weight-medium fs-10">
                          {{ item.symbol ? item.symbol : "" }}
                        </span>
                      </v-chip>
                      <v-chip :color="item.dateof ? '#FFF6E6' : 'secgreen'" x-small :text-color="item.dateof ? '#B37702' : 'maingreen'" style="border-radius: 5px; padding: 10px 8px !important">
                        <span class="font-weight-medium fs-10">
                          {{ item.dateof ? "Upcoming" : "Open" }}
                        </span>
                      </v-chip>
                      <v-chip class="px-0 crd-trn" x-small text-color="#000">
                        {{ item.datebit ? item.datebit : "" }}
                      </v-chip>
                    </v-chip-group>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-row class="py-2" no-glutters>
                  <v-col cols="6">
                    <p class="mb-1 fs-12 subtext--text">Price range</p>
                    <p class="mb-1 fs-12 font-weight-bold maintext--text">₹{{ item.minPrice ? item.minPrice : "0.00" }} - ₹{{ item.maxPrice ? item.maxPrice : "0.00" }}</p>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <p class="mb-1 fs-12 subtext--text">
                      Min. qty: <span class="font-weight-bold maintext--text">{{ item.lotSize ? item.lotSize : "0" }}</span>
                    </p>
                    <p class="mb-1 fs-12 subtext--text">
                      Min. Inv: <span class="font-weight-bold maintext--text">₹{{ item.minPrice ? item.minPrice * item.minBidQuantity : "0.00" }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
              <div class="px-4">
                <v-btn block @click="uid ? IposOrder(item, ipotype) : $emit('gologin')" small class="elevation-0 rounded-pill text-none primary--text font-weight-bold mb-2" color="#F1F3F8">{{ item.dateof ? "Pre" : "" }} Apply</v-btn>
              </div>
              <v-card v-if="uid && showtable - 1 != o" class="pt-1 rounded-0 elevation-0" color="outline"></v-card>
            </v-card>
          </div>
          <v-card v-else class="elevation-0 rounded-0 text-center crd-trn">
            <div class="mx-auto py-8">
              <img class="align-self-stretch mx-auto" width="70px" :src="require('@/assets/no data folder.svg')" />
              <h4 class="txt-999 font-weight-regular fs-14">There is no data here yet!</h4>
            </div>
          </v-card>
          <v-btn v-if="iposdatas[ipotype] && showtable < iposdatas[ipotype].length" color="primary" plain class="elevation-0 text-none font-weight-bold py-4 mb-1" @click="showtable += 24" block>show more</v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <v-toolbar @click="addscript = true" flat dense class="tool-sty crd-trn">
          <p class="mb-0 font-weight-bold">IPO Performance {{ new Date().getFullYear() }}</p>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div v-if="iposdatas[2]">
          <v-row>
            <v-col cols="12" md="6" v-for="(p, t) in iposdatas[2].slice(0, 10)" :key="t">
              <v-card class="rounded-lg crd-trn" outlined>
                <div>
                  <v-card color="secbg" class="elevation-0 rounded-b-0 rounded-t-lg">
                    <v-list-item class="px-2">
                      <v-list-item-content>
                        <v-list-item-title class="mb-0 fs-15">{{ p["Company Name"] }}</v-list-item-title>
                        <v-list-item-subtitle class="fs-12">{{ p.covertdate ? p.covertdate.slice(4, 16) : "" }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <span class="fs-14" :class="p.listing_gain_per > 0 ? 'txt-gre' : p.listing_gain_per < 0 ? 'txt-red' : 'maintext--text'"> {{ p.listing_gain_per ? p.listing_gain_per : "0.00" }}% </span>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-card>
                  <v-card class="elevation-0 rounded-t-0 rounded-b-lg px-2 py-1">
                    <v-row no-glutters>
                      <v-col cols="6" class="subtext--text fs-13"
                        >Issue price<span class="fs-14 maintext--text font-weight-medium"> ₹{{ p.Price_Range }}</span></v-col
                      >
                      <v-col cols="6" class="subtext--text fs-13 text-right"
                        >Listing gain <span class="fs-14 font-weight-medium" :class="p.listing_gain > 0 ? 'txt-gre' : p.listing_gain < 0 ? 'txt-red' : 'maintext--text'"> ₹{{ p.listing_gain ? p.listing_gain : "0.00" }}</span></v-col
                      >
                    </v-row>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-skeleton-loader v-for="n in 8" :key="n" class="rounded-lg mb-3" width="100%" height="90" type="card"></v-skeleton-loader>
        </div>
        <v-btn color="primary" plain class="elevation-0 text-none font-weight-bold py-4 mb-6 mb-md-0 mt-2" to="/ipo/performance" block>Performance dashboard</v-btn>
        <!-- {{iposdatas[2]}} -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import eventBus from "../../../eventBus.js";

export default {
  data: () => ({
    ipotype: 0,
    iposdatas: [],
    loading: true,
    opensearch: null,
    showtable: 24,
    uid: null,
  }),

  computed: {
    tradeheader() {
      return [
        {text: "Stock name", value: "name", width: "36%"},
        {text: "IPO date", value: "date"},
        // { text: 'Listing date', value: 'listdate' },
        {text: "Price range", value: "Price", align: "right"},
        // { text: 'Cutoff Price', value: 'cutoffPrice', align: 'right' },
        {text: "Min. amount", value: "minPrice", align: "right"},
        // { text: 'FV', value: 'faceValue', align: 'right' },
        // { text: 'Lot Size', value: 'lotSize', align: 'right' },
        // { text: 'Frequency', value: 's_prdt_ali' }
      ];
    },
  },
  created() {
    this.token = "";
    this.uid = "";
  },

  async mounted() {
    eventBus.$emit("tabBar-load");
    eventBus.$emit("login-event");
    this.$emit("receive", "ipos");
    eventBus.$on("setRec-event", (value) => {
      if (value == "stat_ok") {
        this.$emit("receive", "ipos");
      } else if (value && value.ipo && value.sme) {
        this.getiposdata(value);
      }
    });
    eventBus.$on("user-event", () => {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.token = sessionStorage.getItem("usession");
        this.uid = sessionStorage.getItem("userid");
      }
    });

    var tab = new URL(window.location.href).searchParams.get("t");
    this.ipotype = tab == "sme" ? 1 : 0;
  },
  beforeDestroy() {
    // eventBus.$off('user-event');
    eventBus.$off("setRec-event");
  },
  methods: {
    getiposdata(receive) {
      this.iposdatas["0"] = [];
      this.iposdatas["1"] = [];
      if (receive && receive.ipo && receive.ipo.MainIPO) {
        receive.ipo &&
          receive.ipo.MainIPO.forEach((element) => {
            element
              ? ((element["dateof"] = this.differentDate(element.biddingStartDate.slice(3, 5) + "-" + element.biddingStartDate.slice(0, 2) + "-" + element.biddingStartDate.slice(-4))),
                (element["datebit"] = `${this.getOrdinal(element.biddingStartDate.slice(0, 2))} - ${this.getOrdinal(element.biddingEndDate.slice(4, 7))}${element.biddingEndDate.slice(7, 16)}`),
                this.iposdatas["0"].push(element))
              : "";
          });
      } else {
        this.iposdatas["0"] = [];
      }
      if (receive.sme && receive.sme.SMEIPO) {
        receive.sme &&
          receive.sme.SMEIPO.forEach((element) => {
            element
              ? ((element["dateof"] = this.differentDate(element.biddingStartDate.slice(3, 5) + "-" + element.biddingStartDate.slice(0, 2) + "-" + element.biddingStartDate.slice(-4))),
                (element["datebit"] = `${this.getOrdinal(element.biddingStartDate.slice(0, 2))} - ${this.getOrdinal(element.biddingEndDate.slice(4, 7))}${element.biddingEndDate.slice(7, 16)}`),
                this.iposdatas["1"].push(element))
              : "";
          });
      } else {
        this.iposdatas["1"] = [];
      }
      this.iposdatas["2"] = receive.perf && receive.perf.data ? receive.perf.data : [];

      if (this.iposdatas["2"] && this.iposdatas["2"].length > 0) {
        this.iposdatas["2"].sort(function (a, b) {
          return new Date(b.covertdate) - new Date(a.covertdate);
        });
      }
      this.loading = false;
    },
    IposOrder(item, type) {
      let res = sessionStorage.getItem("c3RhdHVz");
      if (res == "dmFsaWR1c2Vy") {
        this.$emit("menudialog", "iposorder", item, type);
      } else {
        this.token = "";
        this.uid = "";
        this.$emit("gologin");
      }
    },
    getOrdinal(n) {
      let ord = "th";
      if (n % 10 == 1 && n % 100 != 11) {
        ord = "st";
      } else if (n % 10 == 2 && n % 100 != 12) {
        ord = "nd";
      } else if (n % 10 == 3 && n % 100 != 13) {
        ord = "rd";
      }
      return `${n}${ord}`;
    },
    differentDate(date) {
      var date1 = new Date(date);
      var date2 = new Date();
      var yy = (date1 - date2) / (1000 * 60 * 60 * 24);
      return yy > 0 ? true : false;
    },
  },
};
</script>

<style>
.stk-land-crds {
  background-color: linear-gradient(270deg, #fff 77.04%, #f6f6f6 115%), #fff !important;
  box-shadow: 0px 38.519px 25.482px 0px rgba(83, 30, 0, 0.04), 0px 20px 13px 0px rgba(83, 30, 0, 0.04), 0px 8.148px 6.519px 0px rgba(83, 30, 0, 0.03), 0px 1.852px 3.148px 0px rgba(83, 30, 0, 0.02) !important;
}
</style>
